<template>
  <a-select :allow-clear="allowClear" :filter-option="false" :options="options" placeholder="请选择主机类型" :value="value"
    @change="v => $emit('input', v)"></a-select>
</template>

<script>
export default {
  name: 'HostTypeSelect',
  props: {
    allowClear: { type: Boolean, default: true },
    value: { type: String }
  },
  data () {
    return {
      options: [
        { title: this.$t('host_type.virtual'), value: 'virtual' },
        { title: this.$t('host_type.physical'), value: 'physical' }
      ]
    }
  }
}
</script>
