<template>
  <a-select :allow-clear="allowClear" :filter-option="false" :options="options" placeholder="请选择操作系统类型" :value="value"
    @change="v => $emit('input', v)"></a-select>
</template>

<script>
export default {
  name: 'OsTypeSelect',
  props: {
    allowClear: { type: Boolean, default: true },
    value: { type: String }
  },
  data () {
    return {
      options: [
        { title: this.$t('os_type.unknown'), value: 'unknown' },
        { title: this.$t('os_type.linux'), value: 'linux' },
        { title: this.$t('os_type.windows'), value: 'windows' }
        // { title: this.$t('os_type.macos'), value: 'macos' },
        // { title: this.$t('os_type.freebsd'), value: 'freebsd' },
        // { title: this.$t('os_type.hp_ux'), value: 'hp_ux' },
        // { title: this.$t('os_type.openbsd'), value: 'openbsd' },
        // { title: this.$t('os_type.aix'), value: 'aix' },
        // { title: this.$t('os_type.solaris'), value: 'solaris' }
      ]
    }
  }
}
</script>
